button.sign-out {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(255 0 0 / 61%);
  margin: 8px;
  padding: 6px;
  border-radius: 5px;
  border-color: #ffffff00;
  z-index: 10;
  box-shadow: 0px 1px 3px #000000a1;
}