@font-face {
  font-family: Amatic SC;
  src: url(ShadowsIntoLightTwo-Regular.ttf);
}

@font-face {
  font-family: Menlo;
  src: url(Menlo\ Regular\ 400.ttf);
}

body {
  display: flex;
  justify-content: center;
}

.body {
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 45vw;
  min-width: 800px;
  height: 100vh;
  overflow: auto;
  box-shadow: 0 1px 14px #00000026
}

.page {
  padding: 6% 8% 6% 8%;
  box-sizing: border-box;
  font-family: 'Menlo', 'Courier New', Courier, monospace;
  overflow: auto;
}

a {
  text-decoration: none;
  color: black;
  font-family: Amatic SC;
  font-size: 50px;
  border-right: rgb(255 255 255 / 43%) 3px dashed;
  border-bottom: #00000000 3px solid;
  text-align: center;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  * {
    font-size: 13px;
  }
  a {
    font-size: 33px;
  }
  .body {
    min-width: 300px;
  }
}

a.active {
  border-bottom: rgb(255 255 255 / 43%) 3px solid;
}

a:hover {
  color: rgb(77, 77, 77);
}

a:last-of-type {
  border-right: none;
}

header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: #70aec187;
}
